<template>
  <section id="hero" style="margin-bottom: 5px;">
    <router-link to="/climatizacion" style="text-decoration: none;">
      <v-img
        :min-height="minHeight"
        :src="require('@/assets/heroappliance.jpg')"
        class="white--text"
        gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
      >
        <v-container class="fill-height px-4 py-12">
          <v-responsive
            class="d-flex align-center flex-wrap"
            height="100%"
            max-width="700"
            width="100%"
          >
            <base-heading
              title="Climatización"
              style="word-break: break-all;"
            />
            <base-body>
              Ofrecemos soluciones de climatización adaptadas a tus necesidades,
              para mantener tu hogar fresco en verano y cálido en invierno.
              Contamos con una amplia gama de sistemas de aire acondicionado,
              calefacción y ventilación de última tecnología. Nuestro equipo de
              expertos te asesorará en la selección e instalación del sistema
              ideal, garantizando eficiencia energética y comodidad durante todo
              el año.
            </base-body>

            <div
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'flex-column align-start'
                  : 'align-center'
              "
              class="d-flex flex-wrap"
            ></div>
          </v-responsive>
        </v-container>
      </v-img>
    </router-link>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true }
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  }
};
</script>

<style scoped></style>
